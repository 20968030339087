import Model from '@/shared/classes/model'
import InstructionCategory from '@/shared/modules/instruction-categories/instruction-category.model'
import ICompanyInstructionTemplate
  from '@/shared/modules/company-instruction-template/company-instruction-template-response.interface'

export default class CompanyInstructionTemplate extends Model<ICompanyInstructionTemplate> {
  id!: number
  name!: string
  content!: string
  instructionCategoryId!: number
  instructionCategoryName!: string
  instructionCategory!: InstructionCategory
  instructionTemplateId!: number
  companyUuid!: string
  isSystemTemplate!: boolean

  transform(data: ICompanyInstructionTemplate): void {
    this.id = data.id
    this.name = data.name
    this.content = data.content
    this.instructionCategoryId = data.instruction_category_id
    this.instructionTemplateId = data.instruction_template_id
    this.companyUuid = data.company_uuid
    this.instructionCategory = this.setRelation(InstructionCategory, data.instruction_category)
    this.instructionCategoryName = this.instructionCategory.name
    this.isSystemTemplate = data.is_system_template
  }
}
