















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Form from '@/shared/components/form/Form.vue'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import EditorField from '@/shared/classes/form/fields/editor-field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import CompanyInstructionTemplate
  from '@/shared/modules/company-instruction-template/company-instruction-template.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import CategoryCreateAppend from '@/company/components/views/projects/projects-show/CategoryCreateAppend.vue'

@Component({
  components: { PageHeader, Form },
  methods: { __ },
})
export default class CompanyInstructionTemplatesEditDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/company-instruction-templates`)
      .setAppededEndpointString(`isSystemTemplate=${ this.meta.instructionTemplate.isSystemTemplate }`)
      .setMethod(HttpMethod.POST)
      .setModel(CompanyInstructionTemplate)
      .setTranslatable(true)
      .setValidate(true, { onlyRegionLangRequired: true })
      .setAppendUuidOnSubmit(false)
      .setUuid(this.meta.instructionTemplate.id)
      .setFields([
        new SearchableField()
          .setKey('instruction_category_id')
          .setEntryKey('instructionCategoryId')
          .setDense(true)
          .setAppend(CategoryCreateAppend)
          .isRequired()
          .setTitle(__('company.components.company-instruction-templates.edit-dialog.form.category'))
          .loadItems({ endpoint: '/instruction-categories', value: 'id', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half),
        new Field()
          .setKey('name')
          .isRequired()
          .setDense(true)
          .setTranslatable(true)
          .setSize(FieldSizes.half)
          .setTitle(__('company.components.company-instruction-templates.edit-dialog.form.name')),
        new EditorField()
          .setKey('content')
          .setTranslatable(true)
          .setTitle(__('company.components.company-instruction-templates.edit-dialog.form.content')),
      ])
      .setSubmit({
        text: __('company.components.company-instruction-templates.edit-dialog.form.submit'),
      })
      .setInjectValues({
        instruction_template_id: this.meta.instructionTemplate.isSystemTemplate ? this.meta.instructionTemplate.id : undefined,
        id: this.meta.instructionTemplate.isSystemTemplate ? null : this.meta.instructionTemplate.id,
      })
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
