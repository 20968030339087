





import { Component, Vue } from 'vue-property-decorator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import InstructionCategory from '@/shared/modules/instruction-categories/instruction-category.model'
import CreateInstructionCategoriesDialog
  from '@/admin/components/views/instruction-categories/CreateInstructionCategoriesDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import __ from '@/shared/helpers/__'

@Component
  export default class CategoryCreateAppend extends Vue {
    openInstructionCategoryCreate(): void {
      this.$store.dispatch(GlobalActions.showSecondaryDialog, {
        show: true,
        component: CreateInstructionCategoriesDialog,
        meta: {
          onSuccess: (category: InstructionCategory) => {
            this.$emit('setValue', category.id)
            this.$store.dispatch(GlobalActions.closeSecondaryDialog)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.components.instruction-categories.create-dialog.on-success'),
            })
          }
        }
      })
    }
  }
