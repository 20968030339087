







import { Component, Prop, Vue } from 'vue-property-decorator'
import Project from '@/shared/modules/project/models/project.model'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import can from '@/shared/helpers/can.helper'
import CompanyInstructionTemplate
  from '@/shared/modules/company-instruction-template/company-instruction-template.model'
import SelectAction from '@/shared/classes/components/data-table/data-table-select-actions'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import CompanyInstructionTemplatesCreateDialog
  from '@/company/components/views/projects/projects-show/CompanyInstructionTemplatesCreateDialog.vue'
import CompanyInstructionTemplatesEditDialog
  from '@/company/components/views/projects/projects-show/CompanyInstructionTemplatesEditDialog.vue'
import SelectedTemplateAssign from '@/company/components/views/projects/projects-show/SelectedTemplateAssign.vue'
import CompanyTemplateNameHeader from '@/company/components/views/projects/projects-show/CompanyTemplateNameHeader.vue'
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import CompanyInstructionTemplateService
  from "@/shared/modules/company-instruction-template/company-instruction-template.service";

@Component({
  methods: { can, __ },
  components: { DataTable },
})
export default class CompanyInstructionTemplates extends Vue {
  @Prop() project!: Project

  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(CompanyInstructionTemplate)
      .setMultipleCheckbox(true)
      .setDoubleClick(this.openEditDialog)
      .setGroupSelect(true)
      .setPerPage(100)
      .setEndpoint(`company/${ this.company.slug }/company-instruction-templates`)
      .setGroupBy('instructionCategoryName')
      .setGroupDesc(false)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setValueComponent(CompanyTemplateNameHeader)
          .setText(__('admin.views.instruction-templates.index.table.columns.name')),
      ])
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedTemplateAssign)
          .setEnabled(true)
          .setMeta({
            onSuccess: () => {
              this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('company.components.company-instruction-templates.create-dialog.on-success'),
              })
              this.tableRef.refresh()
            }
          })
      ])
      .setActions([
        new EditAction(false)
          .setAction(this.openEditDialog),
        new DeleteAction()
          .setAllowedForItem((item: CompanyInstructionTemplate) => !item.isSystemTemplate)
          .setAction(this.delete)
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CompanyInstructionTemplatesCreateDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('company.components.company-instruction-templates.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(instructionTemplate: CompanyInstructionTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CompanyInstructionTemplatesEditDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        instructionTemplate,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('company.components.company-instruction-templates.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  delete(item: CompanyInstructionTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.projects.show.tabs.summary.instruction-templates.table.actions.delete.title'),
        text: __('company.views.projects.show.tabs.summary.instruction-templates.table.actions.delete.text', { name: item.name }),
        onYes: () => CompanyInstructionTemplateService.destroy(item.id)
          .then(() => {
            this.table?.removeItem(item)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.projects.show.tabs.summary.instruction-templates.table.actions.delete.on-success')
            })
          })
      }
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
